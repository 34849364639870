
import { View } from 'client-website-ts-library/plugins';
import { Office } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { Component, Mixins } from 'vue-property-decorator';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    ListingSearch,
  },
})
export default class About extends Mixins(View) {
  private office: Office | null = null;

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
  }
}
